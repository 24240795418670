import React from "react";
import { useContext } from "react";
import { NavToggleContext } from "../providers/navToggleProvider";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";
import { NavList } from "../../utils/navList";

export const GlobalNav = () => {
  const { toggle, setToggle } = useContext(NavToggleContext);
  const onClickCloseNav = () => setToggle(false);
  return (
    <SGlobalNav data-toggle-state={!toggle}>
      <SCompany>
        <li>デマンドリンク株式会社</li>
        <li>〒101-0032</li>
        <li>
          東京都千代田区岩本町2-2-10
          <br />
          日鉄岩本町ビルディング4F
        </li>
      </SCompany>
      <SNav>
        {NavList.map(({ href, label, enLabel, key }) => {
          return (
            <Link to={href} key={key} onClick={onClickCloseNav} tabIndex="-1">
              <span>{enLabel}</span>
              <span>{label}</span>
            </Link>
          );
        })}
      </SNav>
    </SGlobalNav>
  );
};

const SGlobalNav = styled.nav`
  position: fixed;
  top: 80px;
  height: calc(100vh - 80px);
  width: 100%;
  background-color: ${Color.primary};
  display: flex;
  overflow: hidden;
  transition: all 0.2s;
  z-index: 99;
  &[data-toggle-state="true"] {
    transform: translateY(-100vh);
  }
  &[data-toggle-state="false"] {
    transform: translateY(0);
  }
  @media screen and (max-width: 599px) {
    top: 64px;
    height: calc(100vh - 64px);
  }
`;

const SCompany = styled.ul`
  width: 40%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  background-color: ${Color.dark};
  li {
    font-size: 1rem;
    color: ${Color.light};
    font-weight: 300;
    text-align: center;
    &:nth-of-type(1) {
      font-weight: 500;
    }
    &:nth-of-type(2) {
      margin-top: 1rem;
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1024px) {
  }
  /* TB */
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const SNav = styled.div`
  width: 60%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    text-decoration: none;
    span {
      font-weight: 500;
      font-size: 1.5rem;
      color: ${Color.light};
      line-height: 1;
      &:last-of-type {
        font-size: 0.9rem;
        margin-top: 4px;
      }
    }
    &:first-of-type {
      margin-top: 0;
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1024px) {
  }
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;
