import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";
import { NavList } from "../../utils/navList";

export const HeaderNav = () => {
  return (
    <SHeaderNav>
      {NavList.map(({ href, label, key }) => {
        return (
          <Link to={href} key={key} tabIndex="-1">
            {label}
          </Link>
        );
      })}
    </SHeaderNav>
  );
};

const SHeaderNav = styled.div`
  height: 100%;
  grid-area: headerNav;
  display: flex;
  justify-content: right;
  margin-right: 2rem;
  a {
    height: 100%;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 700;
    margin-right: 2rem;
    transition: all 0.2s;
    line-height: 1;
    &:last-of-type {
      margin-right: 0;
    }
    @media screen and (min-width: 1025px) {
      &:hover {
        color: ${Color.primary};
      }
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
