import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";
import { FooterNavList } from "../../utils/footerNavList";

export const Footer = () => {
  return (
    <SFooter>
      <SFooterNav>
        {FooterNavList.map(({ href, label, key }) => {
          return (
            <Link to={href} key={key} tabIndex="-1">
              {label}
            </Link>
          );
        })}
      </SFooterNav>
      <SFooterBar>
        <p>このサイトはデモサイトです。</p>
        <span>Demand Link Co.,Ltd.</span>
      </SFooterBar>
    </SFooter>
  );
};

const SFooter = styled.footer`
  border-top: 1px solid ${Color.gray};
  background-color: ${Color.light};
  padding: 2rem;
  /* SP */
  @media screen and (max-width: 599px) {
    padding: 2rem 1rem;
  }
`;

const SFooterNav = styled.nav`
  display: flex;
  justify-content: center;
  a {
    font-size: 0.9rem;
    text-decoration: none;
    margin: 0 1rem;
    &:hover {
      text-decoration: underline;
      color: ${Color.primary};
    }
  }

  /* SP */
  @media screen and (max-width: 599px) {
    a {
      margin: 0 0.5rem;
    }
  }
`;

const SFooterBar = styled.div`
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  margin-top: 2rem;
  p {
    line-height: 1;
    margin-bottom: 0.5rem;
  }
`;
