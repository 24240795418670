import React from "react";
import styled from "@emotion/styled";
import { Logo } from "../logo/logo";
import { HeaderNav } from "./headerNav";
import { LangSwitchBtn } from "../btn/langSwitchBtn";
import { Hamburger } from "../btn/hamburger";

export const Header = (props) => {
  return (
    <SHeader>
      <Logo homeUrl="/" />
      <HeaderNav />
      <LangSwitchBtn jpUrl={props.jpUrl} enUrl={props.enUrl} langTxt="JP" />
      <Hamburger />
    </SHeader>
  );
};

const SHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto 48px;
  grid-template-areas: "logo headerNav langSwitch hamburger";
  height: 80px;
  padding: 0 2rem;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0px 0px 16px -6px #666;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 0 1rem;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 599px) {
    height: 64px;
  }
`;
