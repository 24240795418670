import React from "react";
import "../../styles/global.css";
import styled from "@emotion/styled";
import { Header } from "../header/header";
import { GlobalNav } from "../nav/globalNav";
import { Footer } from "../footer/footer";
import { Seo } from "../seo/seo";
import { NavToggleProvider } from "../providers/navToggleProvider";

export const Layout = (props) => {
  return (
    <NavToggleProvider>
      <Seo title={props.title} lang="ja" />
      <Header jpUrl={props.jpUrl} enUrl={props.enUrl} />
      <GlobalNav />
      <SMain data-main={props.mainWrap}>{props.children}</SMain>
      <Footer />
    </NavToggleProvider>
  );
};

const SMain = styled.main`
  animation-name: pageMove;
  animation-fill-mode: backwards;
  animation-duration: 0.5s;
  @keyframes pageMove {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
